import React, { useState } from 'react';
import { Paper, Button, TextField } from '@material-ui/core';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { SiteMetadata } from '../types/SiteMetadata';
import styled from '../styled';
import Layout from '../layouts/Layout';
import { SEO } from '../components/SEO';

type TagMap = { [name: string]: string };

type Data = {
  allTag: {
    nodes: {
      id: string;
      name: string;
    }[];
  };
  site: {
    siteMetadata: SiteMetadata;
  };
};

const Title = styled('div')`
  color: ${(p) => p.theme.palette.primary.contrastText};
  font-size: 1.8rem;
  margin-top: 4rem;
  margin-bottom: 2rem;
`;

const Img = styled('img')`
  max-width: 100%;
  width: 100%;
  border-radius: 8px;
`;

const Caption = styled('p')`
  font-size: 16px;
  text-align: center;
  opacity: 0.8;
`;

const Content = styled('p')`
  text-align: left;
  font-size: 1.5rem;
  margin: 2rem auto;

  a {
    border-bottom: 2px solid white;
  }

  a:focus {
    outline: blue;
  }
`;

const Li = styled('li')`
  font-size: 1.5rem;
`;

const SubTitle = styled('p')`
  font-size: 0.8em;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.1em;
  color: #69c0ff;
`;

const Photo = styled('img')`
  border-radius: 100%;
  float: right;
  width: 180px;
  padding: 18px;
`;

const Branding = styled('p')`
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.1em;
  color: #fff;
  opacity: 0.8;

  a {
    border-bottom: 2px solid #666;
  }
`;

const FormWrapper = styled(Paper)`
  padding: ${(p) => p.theme.spacing(3)}px ${(p) => p.theme.spacing(6)}px
    ${(p) => p.theme.spacing(6)}px;
`;

const SuccessMessage = styled('div')`
  border: 1px solid #bae637;
  border-radius: 8px;
  background-color: #eaff8f;
  padding: 12px 24px;
  color: #254000;
  font-size: 18px;
`;

const ErrorMessage = styled('div')`
  border: 1px solid #ffc53d;
  border-radius: 8px;
  background-color: #ffe58f;
  color: #613400;
  font-size: 18px;
  padding: 12px 24px;
`;

const YOUR_FORM_ID = '1377786';
const YOUR_SUBFORM_ID = '547';
const YOUR_FORM_URL = `https://app.convertkit.com/forms/${YOUR_FORM_ID}/subscriptions`;

export default function ({
  pageContext,
}: {
  pageContext: { basePath: string };
}) {
  const { basePath } = pageContext;
  const [status, setStatus] = useState<string | null>(null);

  const data: Data = useStaticQuery(graphql`
    query {
      allTag {
        nodes {
          name
          id
        }
      }
      site {
        siteMetadata {
          title
          siteUrl
          description
          headline
          footerText
          footerUrl
        }
      }
    }
  `);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const data = new FormData(e.target);

    try {
      data.append('fields[source]', document.referrer);
      const response = await fetch(YOUR_FORM_URL, {
        method: 'post',
        body: data,
        headers: {
          accept: 'application/json',
        },
      });

      const json = await response.json();

      if (json.status === 'success') {
        setStatus('SUCCESS');
        return;
      }

      setStatus('ERROR');
    } catch (err) {
      setStatus('ERROR');
    }
  };

  const tags: string[] = [];
  const siteMetadata = data.site.siteMetadata;
  const allTags = data.allTag.nodes;
  const tagMap = allTags.reduce<TagMap>((result, tag) => {
    result[tag.name] = tag.id;
    return result;
  }, {});

  console.log(tags);
  console.log(tagMap);

  return (
    <div style={{ padding: '12px' }}>
      <Layout styles={{ maxWidth: 650 }}>
        <SEO
          title={siteMetadata.title}
          description={siteMetadata.description}
          siteUrl={siteMetadata.siteUrl}
        />
        <Title>
          <h1>Your download is on its way!</h1>
          <SubTitle>Welcome to Blogging for Devs</SubTitle>
        </Title>
        <Content>
          Thanks for subscribing to <em>Blogging for Devs</em>. Your PDF
          download is on its way to your inbox right now.
        </Content>
        <Content>Hope you like it, talk soon!</Content>
        <Content>
          - Monica from <Link to="/">bloggingfordevs.com</Link>
        </Content>
      </Layout>
    </div>
  );
}
